.bannerSection .slick-slider.slick-initialized .slick-prev {
    left: 25px;
    z-index: 111;
}

.bannerSection .slick-slider.slick-initialized .slick-next {
    right: 25px;
    z-index: 111;
}

.bannerItem {
    position: relative;
}

.bannerItem:before {
    background: #222222d1;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    content: "";
}

.bannerItem>img {
    filter: brightness(0.3);
}

.bannerItem .shape {
    position: absolute;
    bottom: 0;
}

.bannerDetail {
    position: absolute;
    top: 60%;
    transform: translate(-50%, -60%);
    width: 60%;
    left: 50%;
    text-align: center;
}

.bannerContent .title h6 {
    color: #fff;
    text-transform: capitalize;
}

.bannerContent .title p {
    color: #fff;
}

.bannerContent .title h3 {
    font-size: 75px;
    text-transform: uppercase;
    color: #fff;
    font-weight: 100;
}

.bannerContent .title h3 strong {
    display: block;
    font-weight: bold;
}

.bannerContent .priceSection {
    display: flex;
    align-items: center;
}

.bannerContent .priceSection .text {
    color: rgb(160, 160, 160);
    text-transform: uppercase;
    font-size: 14px;
    letter-spacing: 1px;
    font-weight: 600;
}

.bannerContent .priceSection .number {
    padding: 0 8px;
    color: #0473b8;
    font-weight: bold;
    font-size: 24px;
}

.bannerContent .shopNow {
    display: flex;
    justify-content: center;
    column-gap: 20px;
    margin-top: 30px;
}

.shopNow a {
    color: #fff;
    padding: 10px 20px;
    text-transform: uppercase;
    background: #0473b8;
    font-size: 18px;

}