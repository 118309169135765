.abaris-nav .navbar.navbarItem {
    box-shadow: none;
    padding: 0;
    border-radius: 0;
}

header.sticky{
    transform: translateY(0px);
    position: fixed;
    width: 100%;
    z-index: 1;
}
header.sticky.active {
    position: sticky;
    top: 0px;
    width: 100%;
    z-index: 11;
    transform: translateY(-33px);
    transition: .4s ease-in-out;
    box-shadow: 0px 15px 10px -15px #a9a9a9;
}

header.sticky.active .abaris-nav .navbar .navbar-nav .nav-item a {
    color: #131111;
}
header.sticky.active .abaris-nav .navbar .navbar-nav .nav-item a:hover{
    color: #fff;
}
header.sticky.active .abaris-nav {
    background: #fff;
    border-top: 1px solid #f4faf4;
}

header.sticky.diff {
    position: relative;
    z-index: 111;
}

header.sticky.diff .abaris-nav .navbar .navbar-nav .nav-item a{
    color: #333;
    font-weight: 400;
}