.serviceItemInfo {
    display: flex;
    justify-content: space-between;
    column-gap: 24px;
}

.serviceItem {
    padding: 60px 20px;
    transition: .4s ease-in-out;
    width: 100%;
    text-align: center;
    background: #fff;
}

.serviceItem:hover {
    margin-top: -30px;
}

.serviceItem:nth-child(even) {
    background-color: #0473b8;
}

.serviceItemIcon {
    width: 60px;
    height: 60px;
    background: #fff;
    margin: 0px auto 20px;
    font-size: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid #0473b8;
}

.serviceItemIcon svg {
    font-size: 40px;
    color: #37b74d;
}

.serviceItemIcon .rupee {
    color: #fb9678;
}

.serviceItemIcon .wallet {
    color: #e95454;
}

.serviceItemIcon .mobile {
    color: #0473b8;
}

.serviceItemIcon .offer {
    color: #b9bb41;
}

.serviceItemText h5 {
    text-transform: capitalize;
    font-size: 20px;
}

.serviceItemText p {
    margin: 0;
    font-size: 13px;
    line-height: 20px;
    color: #333;
}

.serviceItemText svg {
    transform: rotate(-45deg);
    font-size: 20px;
}

.serviceItemText a {
    color: #333;
}



@media (max-width: 991px) {
    .serviceItem {
        border: 0;
    }

    .categoryBody {
        justify-content: space-between;
        column-gap: 10px;
    }

    .categoryBody .singleProducts {
        width: 45%;
    }

    .serviceItemInfo {
        flex-wrap: wrap;
        justify-content: center;
    }

    .serviceItem {
        width: 50%;
    }

    .categoryHeader a {
        padding: 2px 6px;
    }
}

@media (max-width: 767px) {
    .featuredContent h5 {
        text-transform: capitalize;
        font-size: 18px;
    }

    .featuredImg img {
        height: 100% !important;
    }

    .serviceItem {
        border: 0 !important;
        width: 50%;
    }
}

@media (max-width: 480px) {
    .serviceItem {
        width: 100%;
    }
}