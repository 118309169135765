.productsCategorySec {
    padding: 50px 0;
    background: url("https://cdn.shopify.com/s/files/1/0563/4860/6619/files/banner.jpg?v=1634889582") no-repeat bottom / cover;
}


.categoryHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
    height: 48px;
}

.categoryHeader .title {
    margin: 0;
    text-transform: capitalize;
    width: 70%;
}

.categoryHeader a {
    display: inline-block;
    text-align: center;
    overflow: hidden;
    z-index: 1;
    background: #0473b8;
    text-transform: uppercase;
    cursor: pointer;
    transition: 0.6s;
    font-size: 12px;
    padding: 2px 7px;
    color: #fff;
    border-radius: 4px;
    transition: .4s ease-in-out;
}

.categoryHeader a:hover {
    color: #fff;
    background-color: #0473b8;
}


.singleProducts a {
    text-align: center;
}

.singleProducts a h3 {
    color: #333;
    font-size: 15px;
    text-transform: capitalize;
    text-align: center;
    line-height: 24px;
}

.singleProducts a img {
    transition: .4s ease-in-out;
    height: 200px;
}

.serviceItemText svg {
    transform: rotate(-45deg);
    font-size: 20px;
}

.brandName {
    font-weight: 400;
    color: #0473b8;
    font-size: 20px;
    text-transform: uppercase;
    margin: 0;
}

.singleProducts {
    margin-right: 20px;
    padding: 20px;
    background: #fff;
    text-align: center;
    transition: .4s ease-in-out;
    border: 2px solid #fff;

}

.singleProducts:hover {
    border: 2px solid #0473b8;
}

.singleProducts a h3 {
    margin: 15px 0 0;
    transition: .4s ease-in-out;
    font-size: 17px;

}

.singleProducts:hover a h3 {
    margin-top: 25px;
}

.singleProducts a img:hover {
    transform: scale(1.1);
}

.singleProducts .sale {
    display: inline-block;
    position: absolute;
    top: -10px;
    z-index: 1;
    padding: 2px 10px;
    text-transform: capitalize;
    color: #fff;
    border-radius: 5px;
    font-size: 13px;
    font-weight: 600;
    letter-spacing: 1px;
    right: 10px;
    text-align: center;
    background: #0473b8;
    display: none;
}

@media (min-width: 768px) and (max-width: 1199px) {
    .categoryBody {
        justify-content: space-between;
        column-gap: 10px;
    }

    .categoryBody .singleProducts {
        width: 48%;
    }
}