.brandsSec {
    padding: 30px 0 50px;
}

.brandsItem ul {
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    column-gap: 15px;
}

.brandsItem ul li {
    width: 25%;
}

.brandsItem ul li a {
    display: flex;
    border-radius: 0;
    border: 2px dashed #131111;
    height: 140px;
    width: 140px;
    align-items: center;
    transition: .8s ease-in-out;
}

.brandsItem ul li a:hover {
    transform: rotate(360deg);
    border: 2px solid #0473b8;
}

.brandsItem ul li a img {
    object-fit: contain;
    width: 90px;
    height: 60px;
    margin: auto;
}