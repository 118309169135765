.logo a {
    display: flex;
    column-gap: 20px;
    align-items: center;
}

.logo a h5 span {
    display: block;
}

.logo a img {
    width: 260px;

}

.logo a h5 {
    margin: 0;
    font-size: 18px;
    font-weight: 400;
}

.alignRight {
    display: flex;
    flex-wrap: wrap;
    column-gap: 15px;
    flex-flow: row-reverse;
}

.searchIcons {
    display: flex;
    align-items: center;
    flex-flow: row-reverse;
    column-gap: 10px;
}

.searchSec {
    background-color: #fff;
    padding: 14px 0;
    position: relative;
}

.searchItem {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

/* Bar Start */
.searchItem .logo {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.bar svg {
    font-size: 29px;
    color: #464646;
    cursor: pointer;
    display: none;
}

/* search Info */
.searchInf {
    background-color: #ebebeb;
    width: 350px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 0;
    border-radius: 0;
}

.searchInf input {
    background-color: #ebebeb;
    padding: 0 15px;
    height: 45px;
    border-radius: 0;
    font-size: 18px;
    border: 0;
}

.searchInf input:focus {
    box-shadow: none;
    border: 0;
    background-color: #ffffff;
}

.rightSearchInfo {
    display: flex;
    align-items: center;
    background-color: #131111;
    border-radius: 0;
}

.searchIcon {
    padding: 0 10px;
}


.allCategory {
    background-color: #ebebeb;
    padding-right: 10px;
}

.allCategory select {
    background-color: transparent;
    border: 0;
    padding: 12px 8px;
    font-size: 18px;
    color: #333333;
}

.allCategory select:focus {
    outline: 0;
}

.searchIcon svg {
    font-size: 22px;
    color: #fff;
    cursor: pointer;
}

/* Call Details */
.callDetail {
    display: flex;
    align-items: center;
    column-gap: 10px;
    padding: 10px 8px;
    border-radius: 0;
    background: #0473b8;
}

.callDetail svg {
    font-size: 20px;
    color: #fff;
}

.callText h6 {
    margin: 0;
    text-transform: uppercase;
    font-size: 18px;
    color: #fff;
    font-weight: 300;

}

.callText p {
    color: #111;
    font-weight: 500;
    line-height: 20px;
    font-size: 14px;
}

.searchIconsItem ul {
    display: flex;
    align-items: center;
    column-gap: 10px;
    margin: 0;
    padding: 0;
}

.searchIconsItem ul li a {
    font-size: 22px;
    color: rgb(26, 26, 26);
    line-height: 20px;
}

.countParent a {
    position: relative;
}

.count {
    display: flex;
    background-color: #0473b8;
    color: #fff;
    font-size: 13px;
    width: 20px;
    height: 20px;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    font-weight: 600;
    position: absolute;
    top: -10px;
    right: -10px;
}


/* Trending */
.treandingSec {
    box-shadow: 0 0 8px 0 rgb(7 7 7 / 10%);
    background: #fff;
    position: absolute;
    width: 100%;
    top: 60px;
    transition: .4s ease-in-out;
    z-index: 1;
}

.trendingItem {
    border-top: none;
    padding: 16px 15px;
}

.trendingTitle {
    font-size: 13px;
    font-weight: bold;
    line-height: 1.38;
    color: #565656;
}

.trendingItem ul {
    margin: 0;
    padding: 0;
    display: flex;
    gap: 5px;
    flex-wrap: wrap;
}

.trendingItem ul li a {
    padding: 9px 8px;
    border: 1px solid #e8e7e6;
    background-color: #fff;
    border-radius: 4px;
    cursor: pointer;
    font-size: 12px;
    color: #7c7c7c;
    transition: .4s ease-in-out;
    text-transform: uppercase;
    font-weight: 500;
}

.trendingItem ul li a:hover {
    color: #fff;
    border: solid 1px #0473b8;
    background-color: #0473b8;
}

.closeBtnDrop {
    font-size: 25px;
    position: absolute;
    top: -140px;
    right: 0;
}

.trendingItem {
    position: relative;
}