.menuList {
    padding: 15px;
}

.menuList h4 {
    font-size: 18px;
    text-transform: capitalize;
    color: #0473b8;
}

.menuList ul {
    padding: 0;
    margin: 0;
}

.menuList ul li a {
    padding: 8px 0;
}

.perent {
    position: relative;
}